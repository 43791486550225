import styled from 'styled-components'

export const RDContainer = styled.div`
  margin-left: 24px;
  margin-right: 16px;
  margin-top: 24px;
`
export const RDSubContainer = styled.div`
  margin-bottom: 24px;
`

export const ReportListTable = styled.div
`
  & > div > div {
    padding-bottom: ${(props) => (props.isPadding && props.isResponsive ? '60px' : '12px')};
  }`

export const ReportListSubHeader = styled.div`
   display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
   margin-top: 16px;
  padding-bottom: 12px;
  width: 100%;
  position: relative;
   @media (max-width: 600px) {
    margin-top: 12px;
  }
`

export const ReportListSubHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  position: relative;
  // gap: 12px;
  // width:100%
`

export const RedirectLink = styled.div`
  color: #027aae;
  cursor: pointer;

  align-items: center;
  a {
    color: inherit;
  }
`
