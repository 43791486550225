import { Colors } from '../../../../utils/consts'

export const isData = (devices) => {
  if (devices.length === 1) {
    return (
      devices[0]?.assessmentStatus?.result &&
      devices[0]?.deviceDetails?.modelname &&
      devices[0]?.deviceDetails?.serialNumber
    )
  }
  return devices.length > 0
}

export function descendingComparator(a, b, orderBy) {
  const aSort =
    a['deviceDetails'][orderBy] &&
    a['deviceDetails'][orderBy].toString().toUpperCase()
  const bSort =
    b['deviceDetails'][orderBy] &&
    b['deviceDetails'][orderBy].toString().toUpperCase()
  if (bSort < aSort) {
    return -1
  }
  if (bSort > aSort) {
    return 1
  }
  return 0
}

export function sort(array, orderBy, orderType) {
  return array.sort((a, b) => {
    return orderType === 'descending'
      ? descendingComparator(a, b, orderBy)
      : -descendingComparator(a, b, orderBy)
  })
}

export const getAssessmentStatusName = (assessmentVal: string, t: any) => {
  switch (assessmentVal) {
    case 'lowRisk':
      return t('ecp-global-reports.risks.lowRisk', 'Low Risk')
    case 'mediumRisk':
      return t('ecp-global-reports.risks.mediumRisk', 'Medium Risk')
    case 'highRisk':
      return t('ecp-global-reports.risks.highRisk', 'High Risk')
    case 'passed':
      return t(`ecp-global-reports.risks.passed`, 'Passed')
      case 'notAssessed':
      return t(`ecp-global-reports.risks.notAssessed`, 'Not Assessed')
    default:
      return ''
  }
}

export const getAssessmentStatus = (result: string, t: any) => {
  let statusName: string, statusColor: string

  switch (result) {
    case 'lowRisk':
      statusName = t('ecp-global-reports.risks.lowRisk', 'Low Risk')
      statusColor = Colors.low
      break
    case 'mediumRisk':
      statusName = t('ecp-global-reports.risks.mediumRisk', 'Medium Risk')
      statusColor = Colors.medium
      break
    case 'highRisk':
      statusName = t('ecp-global-reports.risks.highRisk', 'High Risk')
      statusColor = Colors.high
      break
    case 'passed':
      statusName = t(`ecp-global-reports.risks.passed`, 'Passed')
      statusColor = Colors.passed
      break
    default:
      statusName = ''
      statusColor = ''
  }

  return { statusName, statusColor }
}

export const checkIfZero = (value: number) => {
  return Number.isNaN(value) || value === null ? 0 : value === 0 ? 0.001 : value
}
