import React, { useContext, useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { Table, Card, ProgressIndicator, TableColumns } from '@veneer/core'
import { useParams } from 'react-router-dom'
import { useReports } from '../../../hooks/useReports'
import { SearchList } from '../../SearchList'
import { ReportsFilterButton } from '../../ReportsFilterButton'
import { ReportDetailsCard } from '../Common/ReportDetailsCard'
import { Footer } from '../Common/Footer'
import {
  deviceExportData,
  mapDeviceData,
  setBreadcrumbs,
  useDeviceView
} from '../../../utils/commonMethods'
import GeneralContext from '../../../contexts/GeneralContext'
import * as Wrapper from './styles'
import { TablePaginationText, Tablei18nText,sessionStorageKeys,reportDeviceNotAssessedListTableOrder } from '../../../utils/consts'
import { selectedGroupsFormat } from '../../../utils/genericMethods'
import { isData, sort } from '../Common/ReportUtils'

export const DevicesNotAssessed = () => {
  const { t } = useI18n()
  const { stack, breadcrumbs, navigation } = useContext(GeneralContext)
  const { downLoadingReports, fetchReportsByID } = useReports()
  const { reportId } = useParams()
  const [reportDetails, setReportDetails] = useState(null)
  const [reportDetailsLoading, setReportDetailsLoading] = useState(true)
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [reportDataState, setReportDataState] = useState<any>([])
  const [orderBy, setOrderBy] = React.useState('modelname')
  const [orderType, setOrderType] = React.useState<'ascending' | 'descending'>(
    'ascending'
  )
  const [customerName, setcustomerName] = React.useState('--')
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(4)
  const [collectionInfo, setCollectionInfo] = useState([])
  const [searchText, setSearchText] = useState('')
  const [order, setOrder] = useState(JSON.parse(sessionStorage.getItem(sessionStorageKeys.reportDeviceNotAssessedListColumns)) ?? reportDeviceNotAssessedListTableOrder)

  const { isMobileView, isSearchOpen, setIsSearchOpen } = useDeviceView()

  const reloadDeviceList = () => {
    downLoadingReports(reportId, stack)
      .then((response) => {
        JSON.parse(response[0])?.collectionInfo &&
          setCollectionInfo(JSON.parse(response)?.collectionInfo)
        JSON.parse(response[0])?.customerName &&
          setcustomerName(JSON.parse(response)?.customerName)
        const reportData =
          JSON.parse(response[0]).deviceList ||
          response.map((element) => {
            return JSON.parse(element)
          })
        if (isData(reportData)) {
          setTableData(createTableData(reportData))
          setReportDataState(reportData)
        } else {
          setTableData([])
        }
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }
  const reloadReportDetails = () => {
    fetchReportsByID(reportId)
      .then((response) => {
        setReportDetails(response)
        setBreadcrumbs(breadcrumbs, navigation, t, {
          name: response.fileName || response.reportName
        })
      })
      .catch((e) => {
        console.log(e)
        setReportDetails({})
      })
      .finally(() => {
        setReportDetailsLoading(false)
      })
  }
  const createTableData = (devices, searchValue = '') => {
    return mapDeviceData(devices, t, searchValue)
  }

  const getAssessmentText = (assessmentVal) => {
    let statusName

    switch (assessmentVal) {
      case 'notAssessed':
        statusName = t('ecp-global-reports.risks.notAssessed', 'Not Assessed')
        break
      case 'notVerified':
        statusName = t('ecp-global-reports.risks.notverified', 'Not verified')
        break
      case 'good':
        statusName = t('ecp-global-reports.risks.good', 'Good')
        break
      case 'default':
        statusName = ''
    }
    return statusName
  }

  const createExportTableData = (devices) => {
    return deviceExportData(devices, t, 'notAssessed')
  }
  useEffect(() => {
    reloadDeviceList()
    reloadReportDetails()
  }, [reportId])

  useEffect(() => {
    if (searchText === '') {
      handleSort(null, { orderBy, orderType })
    }
  }, [searchText])

  const totalItems = tableData.length
  const headers = [
    {
      key: 'assessmentStatus',
      label: t(
        'ecp-global-reports.tableHeader.assessmentStatus',
        'Assessment Status'
      )
    },
    {
      key: 'modelname',
      label: t('ecp-global-reports.tableHeader.modelname', 'Model Name')
    },
    {
      key: 'serialNumber',
      label: t('ecp-global-reports.tableHeader.serialNumber', 'Serial Number')
    },
    {
      key: 'group',
      label: t('ecp-global-reports.tableHeader.group', 'Group')
    },
    {
      key: 'notAssessedReason',
      label: t(
        'ecp-global-reports.tableHeader.notAssessedReason',
        'Not Assessed Reason'
      )
    }
  ]

  const handleSort = (_, { id = orderBy, type = orderType }: any) => {
    let searchResult
    let sortedArray
    if (searchText) {
      searchResult = getSearchResult(searchText)
      sortedArray = sort(searchResult, id, type)
      setTableData(createTableData(sortedArray, searchText))
    } else {
      sortedArray = sort(reportDataState, id, type)
      setTableData(createTableData(sortedArray))
    }
    setOrderBy(id)
    setOrderType(type)
  }

  const handlePageChange = (page) => setCurrentPage(page)
  const handlePageSizeChange = (event, option) => {
    setPageSize(option.value)
  }

  const finalData = tableData.slice(
    (currentPage - 1) * pageSize,
    (currentPage - 1) * pageSize + pageSize
  )

  const deviceGroupData = collectionInfo?.length
    ? selectedGroupsFormat(collectionInfo, t)
    : null

  const getSearchResult = (searchValue) => {
    return reportDataState.filter((item) => {
      return (
        getAssessmentText(item?.assessmentStatus?.result)
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1 ||
        item?.collectionInfo[0]?.collectionName
          ?.toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1 ||
        item?.deviceDetails?.modelname
          ?.toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1 ||
        item?.deviceDetails?.serialNumber
          ?.toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1 ||
        t(
          `ecp-global-reports.deviceDetails.assessmentResultReason.${item?.assessmentStatus?.assessmentResultReason}`,
          ''
        )
          ?.toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1
      )
    })
  }

  const handleSearch = (searchValue) => {
    if (searchValue) {
      let searchResult = getSearchResult(searchValue)
      setTableData(createTableData(searchResult, searchValue))
    } else {
      setTableData(createTableData(reportDataState, searchValue))
    }
  }

  const handleOnColumnReorder = (newOrder) => {
    setOrder(newOrder)
    sessionStorage.setItem(
      sessionStorageKeys.reportDeviceNotAssessedListColumns,
      JSON.stringify(newOrder)
    )
  }

  return (
    <Wrapper.RDContainer>
      {reportDetailsLoading || loading ? (
        <Card
          data-testid="reports_Loading_card"
          border="dropShadow"
          content={
            (
              <ProgressIndicator
                data-testid="reports_Progress_Indicator"
                style={{ padding: '3% 50%' }}
                appearance={'circular'}
              />
            ) as any
          }
        />
      ) : !loading ? (
        <ReportDetailsCard
          data-testid="device_Not_Assessed_List_Top_Card"
          reportDetails={reportDetails}
          deviceGroupData={deviceGroupData}
          customerName={customerName}
          headers={headers}
          exportData={createExportTableData(reportDataState)}
          imgDisplay={false}
        />
      ) : (
        ''
      )}
      <Wrapper.ReportListTable
        isPadding={isSearchOpen}
        isResponsive={isMobileView}
      >
        <Table
          columnReorder={true}
          onColumnReorder={handleOnColumnReorder}
          actionArea={
            <Wrapper.ReportListSubHeader>
              <Wrapper.ReportListSubHeaderContainer data-testid="report_Header_Left_List">
                <SearchList
                  data-testid="reports_Search_Filter_Box"
                  searchText={searchText}
                  setCurrentPage={setCurrentPage}
                  setSearchText={setSearchText}
                  handleSearch={handleSearch}
                  isLoading={loading}
                  errorDisabled={reportDataState.length < 1}
                  isMobileView={isMobileView}
                  setIsSearchOpen={setIsSearchOpen}
                />
                <ReportsFilterButton
                  data-testid="reports_Filter_Button"
                  isLoading={loading}
                  errorDisabled={reportDataState.length < 1}
                  isMobileView={isMobileView}
                />
              </Wrapper.ReportListSubHeaderContainer>
            </Wrapper.ReportListSubHeader>
          }
          data-testid="device_Not_Assessed_Table_List_Component"
          i18n={Tablei18nText(t)}
          columns={
            [
              {
                id: 'assessmentStatus',
                label: t(
                  'ecp-global-reports.tableHeader.assessmentStatus',
                  'Assessment Status'
                ),
                sortable: false
              },
              {
                id: 'modelname',
                label: t(
                  'ecp-global-reports.tableHeader.modelname',
                  'Model Name'
                ),

                sortable: finalData?.length > 1 ? true : false,
                required: true
              },
              {
                id: 'serialNumber',
                label: t(
                  'ecp-global-reports.tableHeader.serialNumber',
                  'Serial Number'
                ),
                sortable: false
              },
              {
                id: 'group',
                label: t('ecp-global-reports.tableHeader.group', 'Group'),
                sortable: false
              },
              {
                id: 'notAssessedReason',
                label: t(
                  'ecp-global-reports.tableHeader.notAssessedReason',
                  'Not Assessed Reason'
                ),
                sortable: false
              }
            ] as TableColumns[]
          }
          data={finalData}
          loadingDataLength={3}
          loading={loading}
          onSort={handleSort}
          preferences={{
            sortBy: {
              id: orderBy || 'modelname',
              type: orderType || 'ascending'
            },
            width: [
              { columnId: 'assessmentStatus', width: 180 },
              { columnId: 'modelname', width: 300 },
              { columnId: 'serialNumber', width: 150 },
              { columnId: 'group', width: 170 },
              { columnId: 'notAssessedReason', width: 200 }
            ],
            defaultOrder: reportDeviceNotAssessedListTableOrder,
            order
          }}
          pagination={{
            currentPage,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
            pageSize,
            pageSizeOptions: [
              { value: 2 },
              { value: 4 },
              { value: 8 },
              { value: 10 }
            ],
            i18n: TablePaginationText(t, currentPage, totalItems),
            totalItems
          }}
        />
      </Wrapper.ReportListTable>
      <Wrapper.RDSubContainer data-testid="reports_Footer">
        <Footer data-testid="reports_Footer_Info" />
      </Wrapper.RDSubContainer>
    </Wrapper.RDContainer>
  )
}
