import { useI18n } from '@jarvis/react-portal-addons'
import { TableColumns, TableI18n, TablePreferences } from '@veneer/core'

const environmentVariables = {
  local: {
    SSApiUrl: 'http://localhost:3001/securitymgrfleet',
    deviceCacheAPI: 'http://localhost:3001/devicecache',
    clientId: 'NudlWuf7yCQ8hPLvryfrT97MUa3q6bWe'
  },
  dev: {
    SSApiUrl: 'https://dev-us1.api.ws-hp.com/securitymgrfleet',
    deviceCacheAPI: 'https://dev-us1.api.ws-hp.com/devicecache',
    clientId: 'NudlWuf7yCQ8hPLvryfrT97MUa3q6bWe'
  },
  pie: {
    SSApiUrl: 'https://pie-us1.api.ws-hp.com/securitymgrfleet',
    deviceCacheAPI: 'https://pie-us1.api.ws-hp.com/devicecache',
    clientId: 'NudlWuf7yCQ8hPLvryfrT97MUa3q6bWe'
  },
  stage: {
    SSApiUrl: 'https://stage-us1.api.ws-hp.com/securitymgrfleet',
    deviceCacheAPI: 'https://stage-us1.api.ws-hp.com/devicecache',
    clientId: 'NudlWuf7yCQ8hPLvryfrT97MUa3q6bWe'
  },
  prod: {
    SSApiUrl: 'https://us1.api.ws-hp.com/securitymgrfleet',
    deviceCacheAPI: 'https://us1.api.ws-hp.com/devicecache',
    clientId: 'NudlWuf7yCQ8hPLvryfrT97MUa3q6bWe'
  }
}

export function getEnvConfig(stack) {
  switch (stack) {
    case 'local':
      return environmentVariables.local
    case 0:
      return environmentVariables.dev
    case 1:
      return environmentVariables.pie
    case 2:
      return environmentVariables.stage
    case 3:
      return environmentVariables.prod
    default:
      return environmentVariables.pie
  }
}

export const preferences: TablePreferences = {
  sortBy: {
    id: 'connectionState',
    type: 'descending'
  }
}

export const sessionStorageKeys = {
  reportsListColumns: 'ReportTableOrder',
  reportsPageSize: 'ReportPageSize',
  reportDeviceAssessedListColumns: 'ReportDeviceAssessedTableOrder',
  reportDeviceNotAssessedListColumns: 'ReportDeviceNotAssessedTableOrder',
  reportRecommendationListColumns: 'ReportRecommendationTableOrder',
  reportRemediationListColumns: 'ReportRemediationTableOrder',
  reportJobSummaryListColumns: 'ReportJobSummaryTableOrder'
}
export const reportPageSize = 25
export const reportTableOrder = [
  'reportName',
  'category',
  'reportType',
  'generatedTime',
  'status'
]

export const reportDeviceAssessedListTableOrder = [
  'assessmentStatus',
  'modelname',
  'serialNumber',
  'group',
  'dateAssessed'
]

export const reportDeviceNotAssessedListTableOrder = [
  'assessmentStatus',
  'modelname',
  'serialNumber',
  'group',
  'notAssessedReason'
]

export const reportRecommendationListTableOrder = [
  'assessmentStatus',
  'modelname',
  'serialNumber',
  'group',
  'recommendations',
  'dateAssessed'
]

export const reportRemediationListTableOrder = [
  'assessmentStatus',
  'modelname',
  'serialNumber',
  'group',
  'lastRemediated'
]

export const reportJobSummaryListTableOrder = [
  'jobName',
  'deviceDetail',
  'date',
  'status',
  'copies'
]
export const reportsListColumns = (tableData: any[]): TableColumns[] => {
  const { t } = useI18n()

  return [
    {
      id: 'reportName',
      label: t('ecp-global-reports.reportName', 'Report Name'),
      sortable: false,
      required: true
    },
    {
      id: 'category',
      label: t('ecp-global-reports.category', 'Category'),
      sortable: false
    },
    {
      id: 'reportType',
      label: t('ecp-global-reports.reportDetails.report', 'Report Type'),
      sortable: false
    },
    {
      id: 'generatedTime',
      label: t('ecp-global-reports.generatedTime', 'Date Generated'),
      sortable: tableData?.length > 1 ? true : false
    },
    {
      id: 'status',
      label: t('ecp-global-reports.status', 'Status'),
      sortable: false
    },
    {
      id: 'uid',
      label: 'ID',
      index: 'hidden'
    }
  ]
}
export const Tablei18nText = (t): TableI18n => {
  return {
    actionButton: '',
    cancelButton: t('ecp-global-reports.button.cancel', 'Cancel'),
    clearAllFilters: '',
    columnResizeTooltip: '',
    columnOptions: t('ecp-global-reports.columnOptions.name', 'Column Options'),
    deselectAllItems: '',
    downButton: '',
    itemSelected: '',
    itemsSelected: '',
    noItems: t('ecp-global-reports.columnOptions.noData', 'No Data'),
    resetToDefault: t(
      'ecp-global-reports.columnOptions.reset',
      'Reset to Default'
    ),
    saveButton: t('ecp-global-reports.button.save', 'Save'),
    selectAllItems: '',
    selectAllPageItems: '',
    sortedAscending: '',
    sortedDescending: '',
    upButton: '',
    sortByAscending: '',
    sortByAscendingActive: '',
    sortByDescending: '',
    sortByDescendingActive: ''
  }
}

export const TablePaginationText = (t, currentPage, totalItems) => {
  return {
    currentPage: `Page ${currentPage} (Current Page)`,
    goto: `Go to page ${currentPage}`,
    next: 'Next',
    pageSizeInformation: `${t(
      `ecp-global-reports.executiveSummary.of`,
      'of'
    )} ${totalItems}`,
    paginationNavigation: 'Pagination Navigation',
    previous: 'Previous',
    selectPageSize: 'Select page size'
  }
}

export const CharsNotAllowed = [
  '(',
  ')',
  '*',
  '?',
  '/',
  '\\',
  ':',
  '<',
  '>',
  '|',
  '.'
]
export const filterOptions = {
  sortBy: 'deviceName',
  order: 'ascending',
  offset: 0,
  limit: 10,
  search: '',
  serviceId: 'ws-hp.com/smartsecurity'
}
export const retainJobTimes = [30, 60, 240, 1440, 10080, 40320, 0]
export const showSubSetting = {
  'ews-password': true,
  'snmp-v1-v2': true,
  'snmp-v3': true,
  'pjl-password': false,
  'remote-cfg-password': true,
  'svc-access-code': false,
  'fs-password': false,
  slp: false,
  'ipv4-multicast': false,
  llmnr: false,
  'ws-discovery': false,
  bonjour: false,
  'tcpip-print': false,
  airprint: false,
  'lpd-lpr': false,
  ipp: false,
  ipps: false,
  'ws-print': false,
  'file-erase': false,
  'retain-jobs': true,
  'ctrl-panel-lock': false,
  'ctrl-panel-timeout': false,
  'dc-ports': false,
  'host-usb-pnp': true,
  'check-latest': true,
  'secure-boot-presence': false,
  'intrusion-detect-presence': false,
  'whitelist-presence': false,
  'pjl-command': false,
  'ps-security': false,
  'fax-receive': true,
  'fs-access-protocol': true,
  'disk-encryption': false,
  'verify-certificate': false,
  'wins-port': false,
  'wins-registration': false,
  'csrf-prevention': false,
  'web-encryption': true,
  'https-redirect': false,
  'ews-access': false,
  'info-tab': true,
  'remote-fw-update': false,
  'auto-fw-update': false,
  'fw-downgrade': false,
  'pin-protection': true,
  wifi: true,
  fwu: true
}
export const settingValues = {
  'snmp-v3': {
    'encryption-algorithm': {
      md5: 'MD5/DES',
      sha1: 'SHA-1/AES'
    }
  },
  'web-encryption': {
    ciphers: {
      'aes128-sha': 'AES128-SHA',
      'aes128-sha256': 'AES128-SHA256',
      'aes128-gcm-sha256': 'AES128-GCM-SHA256',
      'aes256-sha': 'AES256-SHA',
      'aes256-sha256': 'AES256-SHA256',
      'aes256-gcm-sha384': 'AES256-GCM-SHA384',
      'des-cbc3-sha': 'DES-CBC3-SHA',
      'rc4-sha': 'RC4-SHA',
      'rc4-md5': 'RC4-MD5',
      'dhe-rsa-aes128-sha': 'DHE-RSA-AES128-SHA',
      'dhe-rsa-aes128-sha256': 'DHE-RSA-AES128-SHA256',
      'dhe-rsa-aes128-gcm-sha256': 'DHE-RSA-AES128-GCM-SHA256',
      'dhe-rsa-aes256-sha': 'DHE-RSA-AES256-SHA',
      'dhe-rsa-aes256-sha256': 'DHE-RSA-AES256-SHA256',
      'dhe-rsa-aes256-gcm-sha384': 'DHE-RSA-AES256-GCM-SHA384',
      'ecdhe-rsa-aes128-sha': 'ECDHE-RSA-AES128-SHA',
      'ecdhe-rsa-aes128-sha256': 'ECDHE-RSA-AES128-SHA256',
      'ecdhe-rsa-aes128-gcm-sha256': 'ECDHE-RSA-AES128-GCM-SHA256',
      'ecdhe-rsa-aes256-sha': 'ECDHE-RSA-AES256-SHA',
      'ecdhe-rsa-aes256-sha384': 'ECDHE-RSA-AES256-SHA384',
      'ecdhe-rsa-aes256-gcm-sha384': 'ECDHE-RSA-AES256-GCM-SHA384',
      'ecdhe-rsa-des-cbc3-sha': 'ECDHE-RSA-DES-CBC3-SHA',
      'ecdhe-rsa-rc4-sha': 'ECDHE-RSA-RC4-SHA'
    },
    'tls-version': {
      '1-0': 'TLS 1.0',
      '1-1': 'TLS 1.1',
      '1-2': 'TLS 1.2',
      '1-3': 'TLS 1.3'
    }
  }
}

export const CreateReportBreadcrumbData = (t) => {
  return {
    key: 'reportCreate',
    text: t('ecp-global-reports.generate.header', 'Generate Report')
  }
}

export const EditReportBreadcrumbData = (t) => {
  return {
    key: 'reportEdit',
    text: t('ecp-global-reports.editReport.header', 'Edit Report')
  }
}

export const ReportDetailsBreadcrumbData = (name) => {
  return {
    key: 'reportDetails',
    text: `${name}`
  }
}

export const ReportDeviceDetailsBreadcrumbData = (
  { name, reportCategory, reportType, reportId },
  t
) => {
  return [
    {
      key: 'reportDetails',
      text: `${name || reportType}`,
      url: `/reports/${reportCategory}/${reportType}/${reportId}`
    },
    {
      key: 'deviceDetails',
      text: t('ecp-global-reports.common.details', `Details`)
    }
  ]
}

export const allDevicesGroupUuid = '00000000-0000-0000-0000-000000000000'

export const filterGroupsOptions = {
  connectionState: '',
  outputLevel: 'minimal',
  sortBy: 'connectionState',
  order: 'descending',
  offset: 0,
  limit: 25,
  search: ''
}
export const getText = (key: String, defaultText: String) => {
  const { t } = useI18n()
  return t(key, defaultText)
}

export const Colors = {
  passed: '#0c9f98',
  low: '#f1c029',
  medium: '#d06702',
  high: '#be1313',
  notAssessed: '#8400be',
  unknown: '#adadad',
  red7: '#a90b0a',
  green6: '#1c7a17',
  gray1: '#f1f1f1',
  gray2: '#ebebeb',
  gray3: '#dbdbdb',
  gray4: '#adadad',
  gray5: '#737373',
  gray6: '#585858',
  gray10: '#212121',
  purple6: '#8400be',
  white: '#ffffff',
  black: '#000000',
  HpBlue: '#0278ab'
}
