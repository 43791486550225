import React, { useContext, useState, useEffect } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { Table, Card, ProgressIndicator, TableColumns } from '@veneer/core'
import { useParams } from 'react-router-dom'
import { useReports } from '../../../../hooks/useReports'
import { SearchList } from '../../../SearchList'
import { ReportsFilterButton } from '../../../ReportsFilterButton'
import { ReportListTableStatus } from '../../Common/ReportListTableStatus'
import { ReportDetailsCard } from '../../Common/ReportDetailsCard'
import { Footer } from '../../Common/Footer'
import {
  deviceExportData,
  getDateAndTime,
  setBreadcrumbs,
  useDeviceView
} from '../../../../utils/commonMethods'
import GeneralContext from '../../../../contexts/GeneralContext'
import * as Wrapper from './styles'
import { TablePaginationText, Tablei18nText, sessionStorageKeys, reportRemediationListTableOrder } from '../../../../utils/consts'
import { selectedGroupsFormat } from '../../../../utils/genericMethods'
import { HighlightText } from '../../../HighlightText'
import {
  getAssessmentStatus,
  getAssessmentStatusName,
  isData,
  sort
} from '../../Common/ReportUtils'

export const Remediation = () => {
  const { t } = useI18n()
  const { navigation, stack, breadcrumbs, locale } = useContext(GeneralContext)
  const { downLoadingReports, fetchReportsByID } = useReports()
  const { reportId, reportCategory, reportType } = useParams()
  const reportMetaData = navigation?.location?.state || ''
  const [reportDetails, setReportDetails] = useState(null)
  const [reportDetailsLoading, setReportDetailsLoading] = useState(true)
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])
  const [reportDataState, setReportDataState] = useState<any>([])
  const [orderBy, setOrderBy] = React.useState<string>('modelname')
  const [orderType, setOrderType] = React.useState<'ascending' | 'descending'>(
    'ascending'
  )
  const [customerName, setcustomerName] = React.useState('--')
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(4)
  const [collectionInfo, setCollectionInfo] = useState([])
  const [searchText, setSearchText] = useState('')
  const [order, setOrder] = useState(JSON.parse(sessionStorage.getItem(sessionStorageKeys.reportRemediationListColumns)) ?? reportRemediationListTableOrder)

  const { isMobileView, isSearchOpen, setIsSearchOpen } = useDeviceView()

  const reloadDeviceList = () => {
    downLoadingReports(reportId, stack)
      .then((response) => {
        JSON.parse(response[0])?.collectionInfo &&
          setCollectionInfo(JSON.parse(response)?.collectionInfo)
        JSON.parse(response[0])?.customerName &&
          setcustomerName(JSON.parse(response)?.customerName)
        const reportData =
          JSON.parse(response[0]).deviceList ||
          response.map((element) => {
            return JSON.parse(element)
          })
        if (isData(reportData)) {
          const sortedArray = sort(reportData, orderBy, orderType)
          setTableData(createTableData(sortedArray))
          setReportDataState(reportData)
        } else {
          setTableData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const reloadReportDetails = () => {
    fetchReportsByID(reportId)
      .then((response) => {
        setReportDetails(response)
        setBreadcrumbs(breadcrumbs, navigation, t, {
          name: response.fileName || response.reportName
        })
      })
      .catch((e) => {
        console.log(e)
        setReportDetails({})
      })
      .finally(() => {
        setReportDetailsLoading(false)
      })
  }

  const createTableData = (devices, searchValue = '') => {
    return devices.map((data) => {
      const device = data
      let { statusName, statusColor } = getAssessmentStatus(
        device?.assessmentStatus?.result,
        t
      )

      return {
        assessmentStatus: (
          <ReportListTableStatus
            statusName={statusName}
            statusColor={statusColor}
            searchItem={searchValue}
          />
        ),
        modelname: (
          <Wrapper.RedirectLink
            onClick={() => {
              device?.detailedReportId &&
                navigation.push(
                  `/reports/${reportCategory}/${reportType}/${reportId}/${device?.deviceDetails?.deviceId}/${device?.detailedReportId}`,
                  reportMetaData
                )
            }}
          >
            <a>
              <HighlightText
                value={device?.deviceDetails?.modelname}
                searchItem={searchValue}
              />
            </a>
          </Wrapper.RedirectLink>
        ),
        serialNumber: (
          <HighlightText
            value={device?.deviceDetails?.serialNumber}
            searchItem={searchValue}
          />
        ),
        group: device?.collectionInfo ? (
          <HighlightText
            value={device?.collectionInfo[0]?.collectionName}
            searchItem={searchValue}
          />
        ) : (
          ''
        ),
        lastRemediated: (
          <HighlightText
            value={getDateAndTime(
              device?.assessmentStatus?.lastRemediatonTimeStamp || null,
              locale
            )}
            searchItem={searchValue}
          />
        )
      }
    })
  }

  const getSearchResult = (searchValue) => {
    return reportDataState.filter((item) => {
      return (
        getAssessmentStatusName(item?.assessmentStatus?.result, t)
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1 ||
        getDateAndTime(item?.assessmentStatus?.lastRemediatonTimeStamp || null)
          ?.toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1 ||
        item?.collectionInfo[0]?.collectionName
          ?.toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1 ||
        item?.deviceDetails?.modelname
          ?.toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1 ||
        item?.deviceDetails?.serialNumber
          ?.toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1
      )
    })
  }

  const handleSearch = (searchValue) => {
    if (searchValue) {
      let searchResult = getSearchResult(searchValue)
      setTableData(createTableData(searchResult, searchValue))
    } else {
      setTableData(createTableData(reportDataState, searchValue))
    }
  }

  const createExportTableData = (devices) => {
    return deviceExportData(devices, t, 'remediation')
  }
  useEffect(() => {
    reloadDeviceList()
    reloadReportDetails()
  }, [reportId])

  useEffect(() => {
    if (searchText === '') {
      handleSort(null, { orderBy, orderType })
    }
  }, [searchText])

  const totalItems = tableData.length

  const finalData = tableData.slice(
    (currentPage - 1) * pageSize,
    (currentPage - 1) * pageSize + pageSize
  )
  const handlePageChange = (page) => setCurrentPage(page)

  const handlePageSizeChange = (event, option) => {
    setPageSize(option.value)
  }

  const handleSort = (_, { id = orderBy, type = orderType }: any) => {
    let searchResult
    let sortedArray
    if (searchText) {
      searchResult = getSearchResult(searchText)
      sortedArray = sort(searchResult, id, type)
      setTableData(createTableData(sortedArray, searchText))
    } else {
      sortedArray = sort(reportDataState, id, type)
      setTableData(createTableData(sortedArray))
    }
    setOrderBy(id)
    setOrderType(type)
  }

  const headers = [
    {
      key: 'assessmentStatus',
      label: t(
        'ecp-global-reports.tableHeader.assessmentStatus',
        'Assessment Status'
      )
    },
    {
      key: 'modelname',
      label: t('ecp-global-reports.tableHeader.modelName', 'Model Name')
    },
    {
      key: 'serialNumber',
      label: t('ecp-global-reports.tableHeader.serialNumber', 'Serial Number')
    },
    {
      key: 'group',
      label: t('ecp-global-reports.tableHeader.group', 'Group')
    },
    {
      key: 'lastRemediated',
      label: t(
        'ecp-global-reports.tableHeader.devicesRemediationSummary',
        'Date Run'
      )
    }
  ]

  const deviceGroupData = collectionInfo?.length
    ? selectedGroupsFormat(collectionInfo, t)
    : null

  const handleOnColumnReorder = (newOrder) => {
    setOrder(newOrder)
    sessionStorage.setItem(
      sessionStorageKeys.reportRemediationListColumns,
      JSON.stringify(newOrder)
    )
  }

  return (
    <Wrapper.RDContainer data-testid="remediation_Component">
      {reportDetailsLoading || loading ? (
        <Card
          border="dropShadow"
          content={
            (
              <ProgressIndicator
                data-testid="reports_Progress_Indicator"
                style={{ padding: '3% 50%' }}
                appearance={'circular'}
              />
            ) as any
          }
        />
      ) : !loading ? (
        <ReportDetailsCard
          data-testid="remediation_List_Top_Card"
          reportDetails={reportDetails}
          deviceGroupData={deviceGroupData}
          customerName={customerName}
          headers={headers}
          exportData={createExportTableData(reportDataState)}
          imgDisplay={false}
        />
      ) : (
        ''
      )}

      <Wrapper.ReportListTable
        withPadding={isSearchOpen}
        isMobileView={isMobileView}
      >
        <Table
          columnReorder={true}
          onColumnReorder={handleOnColumnReorder}
          actionArea={
            <Wrapper.ReportListSubHeader>
              <Wrapper.ReportListSubHeaderContainer data-testid="report-header-left-List">
                <SearchList
                  data-testid="reports_Search_Filter_Box"
                  isLoading={loading}
                  setCurrentPage={setCurrentPage}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  handleSearch={handleSearch}
                  errorDisabled={reportDataState.length < 1}
                  isMobileView={isMobileView}
                  setIsSearchOpen={setIsSearchOpen}
                />
                <ReportsFilterButton
                  data-testid="reports_Filter_Button"
                  isLoading={loading}
                  isMobileView={isMobileView}
                  errorDisabled={reportDataState.length < 1}
                />
              </Wrapper.ReportListSubHeaderContainer>
            </Wrapper.ReportListSubHeader>
          }
          data-testid="remediation_Table_List_Component"
          i18n={Tablei18nText(t)}
          columns={
            [
              {
                id: 'assessmentStatus',
                label: t(
                  'ecp-global-reports.tableHeader.assessmentStatus',
                  'Assessment Status'
                ),
                sortable: false
              },
              {
                id: 'modelname',
                label: t(
                  'ecp-global-reports.tableHeader.modelName',
                  'Model Name'
                ),
                sortable: finalData?.length > 1 ? true : false,
                required: true
              },
              {
                id: 'serialNumber',
                label: t(
                  'ecp-global-reports.tableHeader.serialNumber',
                  'Serial Number'
                ),
                sortable: false
              },
              {
                id: 'group',
                label: t('ecp-global-reports.tableHeader.group', 'Group'),
                sortable: false
              },
              {
                id: 'lastRemediated',
                label: t(
                  'ecp-global-reports.tableHeader.devicesRemediationSummary',
                  'Date Run'
                ),
                sortable: false
              }
            ] as TableColumns[]
          }
          data={finalData}
          onSort={handleSort}
          loading={loading}
          loadingDataLength={3}
          preferences={{
            defaultOrder: reportRemediationListTableOrder,
            order,
            sortBy: {
              id: orderBy || 'modelname',
              type: orderType || 'ascending'
            },
            width: [
              { columnId: 'assessmentStatus', width: 180 },
              { columnId: 'modelName', width: 300 },
              { columnId: 'serialNumber', width: 150 },
              { columnId: 'group', width: 170 },
              { columnId: 'lastRemediated', width: 170 }
            ]
          }}
          pagination={{
            currentPage,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
            pageSize,
            pageSizeOptions: [
              { value: 2 },
              { value: 4 },
              { value: 8 },
              { value: 10 }
            ],
            i18n: TablePaginationText(t, currentPage, totalItems),
            totalItems
          }}
        />
      </Wrapper.ReportListTable>
      <Wrapper.RDSubContainer data-testid="reports_Footer">
        <Footer data-testid="reports_Footer_Info" />
      </Wrapper.RDSubContainer>
    </Wrapper.RDContainer>
  )
}
