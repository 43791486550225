import React from 'react'
import { Colors } from '../../utils/consts'
import * as Wrapper from './style'
import { getDateAndTime, getValue } from '../../utils/commonMethods'
import { showSubSetting } from '../../utils/consts'
import { IconWarningAlt } from '@veneer/core'
import { Tablei18nText } from '../../utils/consts'
import HpLogo from './HpLogo'

export default function DeviceDetailsHTML(data, t, locale) {
  const isData = data?.reportData?.detailedAssessment.length > 0
  const columnTwoAndThree = (policyItem) => {
    return data?.reportType === 'deviceRemediationDetails' ? (
      policyItem.assessmentObject.assessmentResult === 'remediateSucceeded' ? (
        <>
          <Wrapper.AssessmentResult>
            <Wrapper.BoldFont>
              {t('ecp-global-reports.deviceDetails.newValue', 'New Value: ')}
            </Wrapper.BoldFont>
            {getValue(
              policyItem.assessmentObject.policyValue,
              t,
              policyItem.policyItemId
            ) + ', '}
            <Wrapper.BoldFont>
              {t('ecp-global-reports.deviceDetails.oldValue', 'Old Value: ')}
            </Wrapper.BoldFont>
            {getValue(
              policyItem.assessmentObject.deviceValue,
              t,
              policyItem.policyItemId
            )}
          </Wrapper.AssessmentResult>
          <td> </td>
        </>
      ) : (
        <>
          <Wrapper.AssessmentResult>
            {t(
              'ecp-global-reports.deviceDetails.assessmentResultReason.deviceRejectedValue',
              'Remediation Failed'
            )}
          </Wrapper.AssessmentResult>
          <td></td>
        </>
      )
    ) : policyItem.assessmentObject.assessmentResult === 'assessSucceeded' ||
      policyItem.assessmentObject.assessmentResult === 'remediateSucceeded' ? (
      <>
        <Wrapper.AssessmentResult>
          {t(`ecp-global-reports.risks.passed`, 'Passed')}
        </Wrapper.AssessmentResult>
        <td></td>
      </>
    ) : (
      <>
        <Wrapper.AssessmentResult>
          {t(
            'ecp-global-reports.deviceDetails.assessmentResultReason.' +
              policyItem.assessmentObject.assessmentResultReason,
            ''
          )}
        </Wrapper.AssessmentResult>
        <td>
          {policyItem.assessmentObject.assessmentResultReason ===
          'valueMismatch' ? (
            <Wrapper.ValueMismatchContainer>
              <span>
                {t('ecp-global-reports.tableHeader.policy', 'Policy')}
                {': '}
                {getValue(
                  policyItem.assessmentObject.policyValue,
                  t,
                  policyItem.policyItemId
                ) + ', '}
              </span>
              <span>
                {t(
                  'ecp-global-reports.deviceDetails.deviceValue',
                  '"Device: "'
                )}
                {getValue(
                  policyItem.assessmentObject.deviceValue,
                  t,
                  policyItem.policyItemId
                )}
              </span>
            </Wrapper.ValueMismatchContainer>
          ) : (
            t(
              'ecp-global-reports.deviceDetails.assessmentResult.remediateFailed',
              'Cannot Remediate'
            )
          )}
        </td>
      </>
    )
  }
  return (
    <Wrapper.ReportsPDFContent>
      <Wrapper.ReportsBanner>
        <HpLogo data-testid="reports_PDF_LogoHp" />

        <Wrapper.ReportsBannerTitle data-testid="repors_PDF_Title">
          <Wrapper.BannerTextContainer>
            <Wrapper.BannerTextContainerText>
              {t(
                'ecp-global-reports.pdfHeader',
                'Command Center Security Report: '
              )}
            </Wrapper.BannerTextContainerText>
            <div style={{ flex: '1' }}>
              <span style={{ paddingBottom: '4px', color: '#231F20', marginLeft: '3px' }}>
                {t(`ecp-global-reports.reportsType.${data.reportType}`, ' ')}{' '}
              </span>
            </div>
          </Wrapper.BannerTextContainer>
          <Wrapper.ReportsBannerTitle3 data-testid="repors_PDF_SubText">
            <p style={{ paddingTop: '4px' }}>
              {t('ecp-global-reports.deviceDetails.' + data.reportType)}
            </p>
            <Wrapper.ReportsSummaryPDFGenerationTime data-testid="repors_PDF_GenerationTime">
              {data.generatedTime}
            </Wrapper.ReportsSummaryPDFGenerationTime>
          </Wrapper.ReportsBannerTitle3>
        </Wrapper.ReportsBannerTitle>
      </Wrapper.ReportsBanner>
      <Wrapper.ReportsBannerTitle3>
        <Wrapper.ReportsBannerInfo>
          <p
            data-testid="repors_PDF_DeviceGroup"
            style={{
              wordBreak: 'break-word',
              marginTop:
                t(`ecp-global-reports.reportsType.${data.reportType}`, ' ')
                  .length > 40
                  ? '35px'
                  : '20px',
              color: '#656263' 
            }}
          >
            {`${t(
              'ecp-global-reports.reportDetails.deviceGroup',
              'Device Group'
            )}: `}
            <span style={{ color: '#231F20' }}>{data.deviceGroup}</span>
          </p>
        </Wrapper.ReportsBannerInfo>
      </Wrapper.ReportsBannerTitle3>
      <Wrapper.ReportsBannerTitle3>
        <Wrapper.ReportsBannerInfo>
          <p style={{ wordBreak: 'break-word' }}>
            {`${t('ecp-global-reports.reportDetails.customer', 'Customer')}: `}
            <span style={{ color: '#231F20' }}>{data.customerName}</span>  
          </p>
        </Wrapper.ReportsBannerInfo>
      </Wrapper.ReportsBannerTitle3>

      <Wrapper.ReportsPDFIndicator>
        <Wrapper.ReportsDataNullCheck data-testid="repors_PDF_DeviceName">
          {data.deviceName}
        </Wrapper.ReportsDataNullCheck>

        <Wrapper.ReportsPDFRiskIndicatorText>
          <p data-testid="repors_PDF_RiskIndicator_Passed">
            <Wrapper.ReportsRiskDot Color={Colors.passed} />
            {t(`ecp-global-reports.risks.passed`, 'Passed')}
          </p>

          <p data-testid="repors_PDF_RiskIndicator_lowRisk">
            <Wrapper.ReportsRiskDot Color={Colors.low} />
            {t('ecp-global-reports.risks.lowRisk', 'Low Risk')}
          </p>

          <p data-testid="repors_PDF_RiskIndicator_mediumRisk">
            <Wrapper.ReportsRiskDot Color={Colors.medium} />
            {t('ecp-global-reports.risks.mediumRisk', 'Medium Risk')}
          </p>

          <p data-testid="repors_PDF_RiskIndicator_highRisk">
            <Wrapper.ReportsRiskDot Color={Colors.high} />
            {t('ecp-global-reports.risks.highRisk', 'High Risk')}
          </p>
        </Wrapper.ReportsPDFRiskIndicatorText>
      </Wrapper.ReportsPDFIndicator>

      {isData ? (
        data?.reportData?.detailedAssessment?.map((assessmentOBJ, i) => {
          const arrayOfSettings: any = Object.values(assessmentOBJ)[0]
          const category = arrayOfSettings[0]?.category?.split('-')
          return (
            <Wrapper.Table>
              <Wrapper.THead>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '550px'
                  }}
                >
                  <Wrapper.HData>
                    {t(
                      `ecp-global-reports.deviceDetails.accordionHeader.left.${category[0]}`,
                      ''
                    )}
                    <Wrapper.BoldFont data-testid={'SettingsOnPolicy' + i}>
                      {category.length === 2 &&
                        t(
                          `ecp-global-reports.deviceDetails.accordionHeader.left.${category[1]}`,
                          ''
                        )}
                    </Wrapper.BoldFont>
                  </Wrapper.HData>
                  <Wrapper.HData style={{ textAlign: 'right' }}>
                    {`${t(
                      'ecp-global-reports.deviceDetails.accordionHeader.right',
                      ''
                    )} ${getDateAndTime(
                      arrayOfSettings[0]?.assessedTime || null,
                      locale
                    )}`}
                  </Wrapper.HData>
                </div>
              </Wrapper.THead>
              <tbody>
                {arrayOfSettings.map((setting) => {
                  return showSubSetting[setting.settingId] ? (
                    <>
                      <tr>
                        <Wrapper.SettingName>
                          <Wrapper.RiskColor risk={setting.complianceResult} />
                          {t(
                            `ecp-global-reports.settings.${setting.settingId}.name`
                          )}
                        </Wrapper.SettingName>
                      </tr>
                      {setting.policyItemsAssessment
                      .map((policyItem) => {
                        const commonSettingName = t(
                          `ecp-global-reports.settings.common.${
                            policyItem.policyItemId.split('.')[1]
                          }`,
                          ''
                        )
                        return (
                          <tr>
                            <Wrapper.SubSettingName>
                              {commonSettingName === ''
                                ? t(
                                    `ecp-global-reports.settings.${policyItem.policyItemId}`,
                                    ''
                                  )
                                : commonSettingName}
                            </Wrapper.SubSettingName>
                            {columnTwoAndThree(policyItem)}
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      <tr>
                        <Wrapper.SettingName>
                          <Wrapper.RiskColor risk={setting.complianceResult} />
                          {t(
                            `ecp-global-reports.settings.${setting.settingId}.name`
                          )}
                        </Wrapper.SettingName>
                        {columnTwoAndThree(setting.policyItemsAssessment[0])}
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </Wrapper.Table>
          )
        })
      ) : (
        <Wrapper.Table>
          <Wrapper.THead>
            <tr>
              <Wrapper.HData>-</Wrapper.HData>
              <Wrapper.HData></Wrapper.HData>
              <Wrapper.HData
                style={{ textAlign: 'right', paddingRight: '20px' }}
              >{`${t(
                'ecp-global-reports.deviceDetails.ccordionHeader.right',
                ''
              )}-`}</Wrapper.HData>
            </tr>
          </Wrapper.THead>
          <tbody>
            {' '}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: '5% 150%'
              }}
            >
              <span style={{ alignSelf: 'center' }}>
                <IconWarningAlt />
              </span>

              <p
                style={{
                  alignSelf: 'center',
                  whiteSpace: 'nowrap'
                }}
              >
                {Tablei18nText(t).noItems}
              </p>
            </div>
          </tbody>
        </Wrapper.Table>
      )}
    </Wrapper.ReportsPDFContent>
  )
}
