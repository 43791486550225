import React from 'react'

import { SearchList } from '../SearchList'
import { ReportsFilterButton } from '../ReportsFilterButton'
import { ReportsGenerateButton } from '../ReportsGenerateButton'

import * as Wrapper from './styles'

export const ReportListSubHeader = ({
  setSearchText,
  searchText,
  handleSearch,
  loading,
  setCurrentPage,
  errorDisabled = false,
  disableSearchFilter,
  setIsSearchOpen,
  ...props
}) => {


  return (
    <Wrapper.ReportListSubHeader>
      <Wrapper.ReportListSubHeaderContainer data-testid="report-header-left-List">
        <SearchList
          data-testid="reports-search-filter-box"
          isLoading={loading}
          setCurrentPage={setCurrentPage}
          searchText={searchText}
          setSearchText={setSearchText}
          handleSearch={handleSearch}
          errorDisabled={disableSearchFilter || errorDisabled}
          isMobileView={props.isMobileView}
          setIsSearchOpen={setIsSearchOpen}
        />
        <ReportsFilterButton
          errorDisabled={disableSearchFilter || errorDisabled}
          isMobileView={props.isMobileView}
        />
      </Wrapper.ReportListSubHeaderContainer>

      <Wrapper.ReportListSubHeaderContainer data-testid="report-header-right-List">
        <ReportsGenerateButton errorDisabled={errorDisabled} isMobileView={props.isMobileView}/>
      </Wrapper.ReportListSubHeaderContainer>
    </Wrapper.ReportListSubHeader>

  )
}
