import React from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { ProgressIndicator, Scrollbar, Modal } from '@veneer/core'
import { useDeviceView } from '../../../utils/commonMethods'

import './index.scss'
import * as Wrapper from './styles'

export const SampleModal = (props) => {
  const {
    sampleModalOpen,
    setSampleModalOpen,
    modalSampleImage,
    reportID,
    loadingSampleImg
  } = props
  const { t } = useI18n()
  const { isMobileView } = useDeviceView()
  return (
    <Modal
      id="reports_SampleModal"
      data-testid="reports_SampleModal"
      closeButton
      maxWidth={'65vw'}
      align="start"
      className="sampleModalReports"
      show={sampleModalOpen}
      expanded={true}
      closeOnBlur={false}
      onClose={() => setSampleModalOpen(false)}
    >
        <Wrapper.ReportsModalHeader
          data-testid="reports_SampleModal_Header"
          id="reports_SampleModal_Header"
        >
          {t('ecp-global-reports.sampleReports.sample', 'Sample:')}{' '}
          {t(`ecp-global-reports.reportsType.${reportID}`, '')}
        </Wrapper.ReportsModalHeader>

        <Wrapper.ReportsModalSubHeader
          data-testid="reports_SampleModal_Subheader"
          id="reports_SampleModal_Subheader"
        >
          {t(`ecp-global-reports.sampleReports.${reportID}`, '')}
        </Wrapper.ReportsModalSubHeader>

        <Scrollbar
          style={isMobileView ? { maxHeight: '50vh' } : { maxHeight: '70vh' }}
          data-testid="reports_SampleModal_Scrollbar"
          id="reports_SampleModal_Scrollbar"
        >
          <div style={{ whiteSpace: 'nowrap' }}>
            {loadingSampleImg ? (
              <ProgressIndicator
                style={{ padding: '3% 50%' }}
                data-testid="reports_SampleModal_Progress"
                id="reports_SampleModal_Progress"
              />
            ) : (
              modalSampleImage
            )}
          </div>
        </Scrollbar>
    </Modal>
  )
}
